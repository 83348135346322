@import url(https://fonts.googleapis.com/css?family=Maven+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans);
@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  padding: 0;
  font-family: 'Maven Pro', sans-serif;
}

.modal-links-grid {
  width: 800px !important;
}



.header-container{flex:0 0 auto}.header-container a{color:#777}.header-container a:visited,.header-container a:active,.header-container a:link{color:#777}.header-container a:active,.header-container a:focus{text-decoration:none}.header-container .navbar{margin-bottom:20px;background-color:#f8f8f8;border-bottom:1px solid #e7e7e7;flex-direction:column;background-image:linear-gradient(180deg, #fff 0, #f8f8f8);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff",endColorstr="#fff8f8f8",GradientType=0);filter:progid:DXImageTransform.Microsoft.gradient(enabled=false);background-repeat:repeat-x;border-radius:4px;box-shadow:inset 0 1px 0 rgba(255,255,255,.15),0 1px 5px rgba(0,0,0,.1)}.header-container .nav-item:hover,.header-container .dropdown--link:hover{background-color:#71a8dc}.header-container .nav-item.session-clock-nav-item:hover,.header-container .dropdown--link.session-clock-nav-item:hover{background:none}.header-container .navbar-brand>img{height:20px}.header-container .navbar-nav>.nav-item>a,.header-container .navbar-nav>.nav-item>span{position:relative;display:block;padding:10px 15px;color:#777;cursor:pointer}.header-container .dropdown--link{padding:10px}@media(min-width: 768px){.header-container .navbar-nav>.nav-item{float:left}.header-container .navbar-nav>.nav-item>a,.header-container .navbar-nav>.nav-item>span{display:block;padding-top:15px;padding-bottom:15px}}
@font-face {
  font-family: 'Glyphicons Halflings';
  
  src: url(/static/media/glyphicons-halflings-regular.5be1347c.eot);
  src: url(/static/media/glyphicons-halflings-regular.5be1347c.eot?#iefix) format('embedded-opentype'), url(/static/media/glyphicons-halflings-regular.be810be3.woff2) format('woff2'), url(/static/media/glyphicons-halflings-regular.82b1212e.woff) format('woff'), url(/static/media/glyphicons-halflings-regular.4692b9ec.ttf) format('truetype'), url(/static/media/glyphicons-halflings-regular.060b2710.svg#glyphicons_halflingsregular) format('svg');
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-asterisk:before {
  content: "\002a";
}
.glyphicon-plus:before {
  content: "\002b";
}
.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}
.glyphicon-minus:before {
  content: "\2212";
}
.glyphicon-cloud:before {
  content: "\2601";
}
.glyphicon-envelope:before {
  content: "\2709";
}
.glyphicon-pencil:before {
  content: "\270f";
}
.glyphicon-glass:before {
  content: "\e001";
}
.glyphicon-music:before {
  content: "\e002";
}
.glyphicon-search:before {
  content: "\e003";
}
.glyphicon-heart:before {
  content: "\e005";
}
.glyphicon-star:before {
  content: "\e006";
}
.glyphicon-star-empty:before {
  content: "\e007";
}
.glyphicon-user:before {
  content: "\e008";
}
.glyphicon-film:before {
  content: "\e009";
}
.glyphicon-th-large:before {
  content: "\e010";
}
.glyphicon-th:before {
  content: "\e011";
}
.glyphicon-th-list:before {
  content: "\e012";
}
.glyphicon-ok:before {
  content: "\e013";
}
.glyphicon-remove:before {
  content: "\e014";
}
.glyphicon-zoom-in:before {
  content: "\e015";
}
.glyphicon-zoom-out:before {
  content: "\e016";
}
.glyphicon-off:before {
  content: "\e017";
}
.glyphicon-signal:before {
  content: "\e018";
}
.glyphicon-cog:before {
  content: "\e019";
}
.glyphicon-trash:before {
  content: "\e020";
}
.glyphicon-home:before {
  content: "\e021";
}
.glyphicon-file:before {
  content: "\e022";
}
.glyphicon-time:before {
  content: "\e023";
}
.glyphicon-road:before {
  content: "\e024";
}
.glyphicon-download-alt:before {
  content: "\e025";
}
.glyphicon-download:before {
  content: "\e026";
}
.glyphicon-upload:before {
  content: "\e027";
}
.glyphicon-inbox:before {
  content: "\e028";
}
.glyphicon-play-circle:before {
  content: "\e029";
}
.glyphicon-repeat:before {
  content: "\e030";
}
.glyphicon-refresh:before {
  content: "\e031";
}
.glyphicon-list-alt:before {
  content: "\e032";
}
.glyphicon-lock:before {
  content: "\e033";
}
.glyphicon-flag:before {
  content: "\e034";
}
.glyphicon-headphones:before {
  content: "\e035";
}
.glyphicon-volume-off:before {
  content: "\e036";
}
.glyphicon-volume-down:before {
  content: "\e037";
}
.glyphicon-volume-up:before {
  content: "\e038";
}
.glyphicon-qrcode:before {
  content: "\e039";
}
.glyphicon-barcode:before {
  content: "\e040";
}
.glyphicon-tag:before {
  content: "\e041";
}
.glyphicon-tags:before {
  content: "\e042";
}
.glyphicon-book:before {
  content: "\e043";
}
.glyphicon-bookmark:before {
  content: "\e044";
}
.glyphicon-print:before {
  content: "\e045";
}
.glyphicon-camera:before {
  content: "\e046";
}
.glyphicon-font:before {
  content: "\e047";
}
.glyphicon-bold:before {
  content: "\e048";
}
.glyphicon-italic:before {
  content: "\e049";
}
.glyphicon-text-height:before {
  content: "\e050";
}
.glyphicon-text-width:before {
  content: "\e051";
}
.glyphicon-align-left:before {
  content: "\e052";
}
.glyphicon-align-center:before {
  content: "\e053";
}
.glyphicon-align-right:before {
  content: "\e054";
}
.glyphicon-align-justify:before {
  content: "\e055";
}
.glyphicon-list:before {
  content: "\e056";
}
.glyphicon-indent-left:before {
  content: "\e057";
}
.glyphicon-indent-right:before {
  content: "\e058";
}
.glyphicon-facetime-video:before {
  content: "\e059";
}
.glyphicon-picture:before {
  content: "\e060";
}
.glyphicon-map-marker:before {
  content: "\e062";
}
.glyphicon-adjust:before {
  content: "\e063";
}
.glyphicon-tint:before {
  content: "\e064";
}
.glyphicon-edit:before {
  content: "\e065";
}
.glyphicon-share:before {
  content: "\e066";
}
.glyphicon-check:before {
  content: "\e067";
}
.glyphicon-move:before {
  content: "\e068";
}
.glyphicon-step-backward:before {
  content: "\e069";
}
.glyphicon-fast-backward:before {
  content: "\e070";
}
.glyphicon-backward:before {
  content: "\e071";
}
.glyphicon-play:before {
  content: "\e072";
}
.glyphicon-pause:before {
  content: "\e073";
}
.glyphicon-stop:before {
  content: "\e074";
}
.glyphicon-forward:before {
  content: "\e075";
}
.glyphicon-fast-forward:before {
  content: "\e076";
}
.glyphicon-step-forward:before {
  content: "\e077";
}
.glyphicon-eject:before {
  content: "\e078";
}
.glyphicon-chevron-left:before {
  content: "\e079";
}
.glyphicon-chevron-right:before {
  content: "\e080";
}
.glyphicon-plus-sign:before {
  content: "\e081";
}
.glyphicon-minus-sign:before {
  content: "\e082";
}
.glyphicon-remove-sign:before {
  content: "\e083";
}
.glyphicon-ok-sign:before {
  content: "\e084";
}
.glyphicon-question-sign:before {
  content: "\e085";
}
.glyphicon-info-sign:before {
  content: "\e086";
}
.glyphicon-screenshot:before {
  content: "\e087";
}
.glyphicon-remove-circle:before {
  content: "\e088";
}
.glyphicon-ok-circle:before {
  content: "\e089";
}
.glyphicon-ban-circle:before {
  content: "\e090";
}
.glyphicon-arrow-left:before {
  content: "\e091";
}
.glyphicon-arrow-right:before {
  content: "\e092";
}
.glyphicon-arrow-up:before {
  content: "\e093";
}
.glyphicon-arrow-down:before {
  content: "\e094";
}
.glyphicon-share-alt:before {
  content: "\e095";
}
.glyphicon-resize-full:before {
  content: "\e096";
}
.glyphicon-resize-small:before {
  content: "\e097";
}
.glyphicon-exclamation-sign:before {
  content: "\e101";
}
.glyphicon-gift:before {
  content: "\e102";
}
.glyphicon-leaf:before {
  content: "\e103";
}
.glyphicon-fire:before {
  content: "\e104";
}
.glyphicon-eye-open:before {
  content: "\e105";
}
.glyphicon-eye-close:before {
  content: "\e106";
}
.glyphicon-warning-sign:before {
  content: "\e107";
}
.glyphicon-plane:before {
  content: "\e108";
}
.glyphicon-calendar:before {
  content: "\e109";
}
.glyphicon-random:before {
  content: "\e110";
}
.glyphicon-comment:before {
  content: "\e111";
}
.glyphicon-magnet:before {
  content: "\e112";
}
.glyphicon-chevron-up:before {
  content: "\e113";
}
.glyphicon-chevron-down:before {
  content: "\e114";
}
.glyphicon-retweet:before {
  content: "\e115";
}
.glyphicon-shopping-cart:before {
  content: "\e116";
}
.glyphicon-folder-close:before {
  content: "\e117";
}
.glyphicon-folder-open:before {
  content: "\e118";
}
.glyphicon-resize-vertical:before {
  content: "\e119";
}
.glyphicon-resize-horizontal:before {
  content: "\e120";
}
.glyphicon-hdd:before {
  content: "\e121";
}
.glyphicon-bullhorn:before {
  content: "\e122";
}
.glyphicon-bell:before {
  content: "\e123";
}
.glyphicon-certificate:before {
  content: "\e124";
}
.glyphicon-thumbs-up:before {
  content: "\e125";
}
.glyphicon-thumbs-down:before {
  content: "\e126";
}
.glyphicon-hand-right:before {
  content: "\e127";
}
.glyphicon-hand-left:before {
  content: "\e128";
}
.glyphicon-hand-up:before {
  content: "\e129";
}
.glyphicon-hand-down:before {
  content: "\e130";
}
.glyphicon-circle-arrow-right:before {
  content: "\e131";
}
.glyphicon-circle-arrow-left:before {
  content: "\e132";
}
.glyphicon-circle-arrow-up:before {
  content: "\e133";
}
.glyphicon-circle-arrow-down:before {
  content: "\e134";
}
.glyphicon-globe:before {
  content: "\e135";
}
.glyphicon-wrench:before {
  content: "\e136";
}
.glyphicon-tasks:before {
  content: "\e137";
}
.glyphicon-filter:before {
  content: "\e138";
}
.glyphicon-briefcase:before {
  content: "\e139";
}
.glyphicon-fullscreen:before {
  content: "\e140";
}
.glyphicon-dashboard:before {
  content: "\e141";
}
.glyphicon-paperclip:before {
  content: "\e142";
}
.glyphicon-heart-empty:before {
  content: "\e143";
}
.glyphicon-link:before {
  content: "\e144";
}
.glyphicon-phone:before {
  content: "\e145";
}
.glyphicon-pushpin:before {
  content: "\e146";
}
.glyphicon-usd:before {
  content: "\e148";
}
.glyphicon-gbp:before {
  content: "\e149";
}
.glyphicon-sort:before {
  content: "\e150";
}
.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}
.glyphicon-sort-by-order:before {
  content: "\e153";
}
.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}
.glyphicon-sort-by-attributes:before {
  content: "\e155";
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}
.glyphicon-unchecked:before {
  content: "\e157";
}
.glyphicon-expand:before {
  content: "\e158";
}
.glyphicon-collapse-down:before {
  content: "\e159";
}
.glyphicon-collapse-up:before {
  content: "\e160";
}
.glyphicon-log-in:before {
  content: "\e161";
}
.glyphicon-flash:before {
  content: "\e162";
}
.glyphicon-log-out:before {
  content: "\e163";
}
.glyphicon-new-window:before {
  content: "\e164";
}
.glyphicon-record:before {
  content: "\e165";
}
.glyphicon-save:before {
  content: "\e166";
}
.glyphicon-open:before {
  content: "\e167";
}
.glyphicon-saved:before {
  content: "\e168";
}
.glyphicon-import:before {
  content: "\e169";
}
.glyphicon-export:before {
  content: "\e170";
}
.glyphicon-send:before {
  content: "\e171";
}
.glyphicon-floppy-disk:before {
  content: "\e172";
}
.glyphicon-floppy-saved:before {
  content: "\e173";
}
.glyphicon-floppy-remove:before {
  content: "\e174";
}
.glyphicon-floppy-save:before {
  content: "\e175";
}
.glyphicon-floppy-open:before {
  content: "\e176";
}
.glyphicon-credit-card:before {
  content: "\e177";
}
.glyphicon-transfer:before {
  content: "\e178";
}
.glyphicon-cutlery:before {
  content: "\e179";
}
.glyphicon-header:before {
  content: "\e180";
}
.glyphicon-compressed:before {
  content: "\e181";
}
.glyphicon-earphone:before {
  content: "\e182";
}
.glyphicon-phone-alt:before {
  content: "\e183";
}
.glyphicon-tower:before {
  content: "\e184";
}
.glyphicon-stats:before {
  content: "\e185";
}
.glyphicon-sd-video:before {
  content: "\e186";
}
.glyphicon-hd-video:before {
  content: "\e187";
}
.glyphicon-subtitles:before {
  content: "\e188";
}
.glyphicon-sound-stereo:before {
  content: "\e189";
}
.glyphicon-sound-dolby:before {
  content: "\e190";
}
.glyphicon-sound-5-1:before {
  content: "\e191";
}
.glyphicon-sound-6-1:before {
  content: "\e192";
}
.glyphicon-sound-7-1:before {
  content: "\e193";
}
.glyphicon-copyright-mark:before {
  content: "\e194";
}
.glyphicon-registration-mark:before {
  content: "\e195";
}
.glyphicon-cloud-download:before {
  content: "\e197";
}
.glyphicon-cloud-upload:before {
  content: "\e198";
}
.glyphicon-tree-conifer:before {
  content: "\e199";
}
.glyphicon-tree-deciduous:before {
  content: "\e200";
}
.glyphicon-cd:before {
  content: "\e201";
}
.glyphicon-save-file:before {
  content: "\e202";
}
.glyphicon-open-file:before {
  content: "\e203";
}
.glyphicon-level-up:before {
  content: "\e204";
}
.glyphicon-copy:before {
  content: "\e205";
}
.glyphicon-paste:before {
  content: "\e206";
}
.glyphicon-alert:before {
  content: "\e209";
}
.glyphicon-equalizer:before {
  content: "\e210";
}
.glyphicon-king:before {
  content: "\e211";
}
.glyphicon-queen:before {
  content: "\e212";
}
.glyphicon-pawn:before {
  content: "\e213";
}
.glyphicon-bishop:before {
  content: "\e214";
}
.glyphicon-knight:before {
  content: "\e215";
}
.glyphicon-baby-formula:before {
  content: "\e216";
}
.glyphicon-tent:before {
  content: "\26fa";
}
.glyphicon-blackboard:before {
  content: "\e218";
}
.glyphicon-bed:before {
  content: "\e219";
}
.glyphicon-apple:before {
  content: "\f8ff";
}
.glyphicon-erase:before {
  content: "\e221";
}
.glyphicon-hourglass:before {
  content: "\231b";
}
.glyphicon-lamp:before {
  content: "\e223";
}
.glyphicon-duplicate:before {
  content: "\e224";
}
.glyphicon-piggy-bank:before {
  content: "\e225";
}
.glyphicon-scissors:before {
  content: "\e226";
}
.glyphicon-bitcoin:before {
  content: "\e227";
}
.glyphicon-btc:before {
  content: "\e227";
}
.glyphicon-xbt:before {
  content: "\e227";
}
.glyphicon-yen:before {
  content: "\00a5";
}
.glyphicon-jpy:before {
  content: "\00a5";
}
.glyphicon-ruble:before {
  content: "\20bd";
}
.glyphicon-rub:before {
  content: "\20bd";
}
.glyphicon-scale:before {
  content: "\e230";
}
.glyphicon-ice-lolly:before {
  content: "\e231";
}
.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}
.glyphicon-education:before {
  content: "\e233";
}
.glyphicon-option-horizontal:before {
  content: "\e234";
}
.glyphicon-option-vertical:before {
  content: "\e235";
}
.glyphicon-menu-hamburger:before {
  content: "\e236";
}
.glyphicon-modal-window:before {
  content: "\e237";
}
.glyphicon-oil:before {
  content: "\e238";
}
.glyphicon-grain:before {
  content: "\e239";
}
.glyphicon-sunglasses:before {
  content: "\e240";
}
.glyphicon-text-size:before {
  content: "\e241";
}
.glyphicon-text-color:before {
  content: "\e242";
}
.glyphicon-text-background:before {
  content: "\e243";
}
.glyphicon-object-align-top:before {
  content: "\e244";
}
.glyphicon-object-align-bottom:before {
  content: "\e245";
}
.glyphicon-object-align-horizontal:before {
  content: "\e246";
}
.glyphicon-object-align-left:before {
  content: "\e247";
}
.glyphicon-object-align-vertical:before {
  content: "\e248";
}
.glyphicon-object-align-right:before {
  content: "\e249";
}
.glyphicon-triangle-right:before {
  content: "\e250";
}
.glyphicon-triangle-left:before {
  content: "\e251";
}
.glyphicon-triangle-bottom:before {
  content: "\e252";
}
.glyphicon-triangle-top:before {
  content: "\e253";
}
.glyphicon-console:before {
  content: "\e254";
}
.glyphicon-superscript:before {
  content: "\e255";
}
.glyphicon-subscript:before {
  content: "\e256";
}
.glyphicon-menu-left:before {
  content: "\e257";
}
.glyphicon-menu-right:before {
  content: "\e258";
}
.glyphicon-menu-down:before {
  content: "\e259";
}
.glyphicon-menu-up:before {
  content: "\e260";
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5, h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

/* .row>* {
    padding: 0;
    width: auto;
} */

.nav-link {
  color: #337ab7;
}

a,
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: #337ab7;
  cursor: pointer;
  text-decoration: none;
}

p {
  margin: 0 0 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.form-label {
  margin-bottom: 5px;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-secondary,
.btn-secondary.disabled, 
.btn-secondary:disabled,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  color: rgb(51, 51, 51);
  background-color: rgb(240, 240, 240);
  border: none;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-default {
  text-shadow: 0 1px 0 #fff;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default.active, .btn-default:active, .btn-default:hover, .open>.dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: all !important;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: .65;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning:hover {
  color: #fff;
  background-color: #eb9316;
  background-position: 0 -15px;
}

.btn-warning.active, .btn-warning:active, .btn-warning:hover, .open>.dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info:hover {
  color: #fff;
  background-color: #2aabd2;
  background-position: 0 -15px;
}

.btn-info.active, .btn-info:active, .btn-info:hover, .open>.dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-success:focus, .btn-success:hover {
  color: #fff;
}

.btn-danger:focus, .btn-danger:hover {
  background-color: #c12e2a;
  background-position: 0 -15px;
}

.btn-danger.active, .btn-danger:active, .btn-danger:hover, .open>.dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger, .btn-default, .btn-info, .btn-primary, .btn-success, .btn-warning {
  text-shadow: 0 -1px 0 rgb(0 0 0 / 20%);
  box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}

.btn-primary.disabled, .btn-primary.disabled.active, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled], .btn-primary[disabled].active, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #265a88;
  background-image: none;
}

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.form-control {
  width: 100%;
  min-height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:not(select) {
  background-image: none;
}

.form-control, output {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-check-input {
  border-color: rgb(51,51,51);
}

.form-check-input:checked {
  background-color: rgb(110,110,110);
  border-color: rgb(51,51,51);
}

.form-check label {
  font-weight: 400;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.btn-close {
  float: right;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

button.btn-close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  border: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.modal-header .btn-close {
  margin-top: -2px;
}

.modal .modal-content .modal-header {
  padding: 1rem !important;
}

.modal .modal-content .modal-footer {
  padding: 1rem !important;
}

.modal-600 {
  min-width: 600px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  transition: width .6s ease;
  overflow: visible;
}

.btn-group-sm>.btn, .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.modal .modal-content .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  padding: 2rem !important;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-default>.panel-heading {
  background-image: linear-gradient(180deg,#f5f5f5 0,#e8e8e8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff5f5f5",endColorstr="#ffe8e8e8",GradientType=0);
  background-repeat: repeat-x;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-default>.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-heading .accordion-button {
  position: default;
  display: default;
  align-items: default;
  padding: 0;
  font-size: auto;
  color: inherit;
  text-align: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
}

.panel-heading .accordion-button:after {
  background: none;
}

.panel-heading .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.panel-heading .accordion-button:focus {
  outline: none;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}
.content-container{overflow:auto;flex:1 1 auto}.vddq-grid{height:500px;width:100%}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body{
  max-width: 100%;
  overflow-x: hidden;
  font-size: 14px;
  font-family:'Work Sans', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(51, 51, 51);
}

.App {
  text-align: center;
}

/* .spinner {
  position: absolute;
  top: 90%;
    left: 50%;
  width: 100%;
  z-index: -1;
  } */

.react-datepicker__header {
  font-size: 10px;

}

.slide-pane__content {
  padding: 0px
}

.react-datepicker__month-container {
  font-size: 11px;
  z-index: 1040;
}

.spinner-local { 
  position: absolute;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  bottom: 50%;
  left: 50%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-wrapper {
  height: 34px;
}

.react-datepicker__input-container {
  border: 1px solid rgb(206, 210, 214);
  border-radius: 0.25rem;
  background-color: #f4f7f9;
  height: 34px;
  padding: 0 !important;
  /* padding: 0.5rem 0.75rem;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5; */
  color: #e4e5e6;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 34px;
  font-size: 17px;
  border: none;
  color: #212529;
  padding: 0 7px;
}

.react-datepicker__input-container input:focus-visible {
  outline: none !important;
}

/* .input-date {
  width: 100%;
  height: 100%;
  border: none;
  color: #212529;
}

.input-date:focus-visible {
  outline: none !important;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.template-small-card {
  height: 180px;
  width: 300px;
  margin: 10px;
  padding: 10px;
  display: inline-block;
  text-align: center;
}

.shadow {
  box-shadow: 1px 10px 23px -10px rgba(0,0,0,0.2);
}


@media (min-width: 1200px){
  .container {
    width: 1250px;
  }
}

.fp-prev:before {
  color:#000;
  content: '\f35a'; /* replace / with \ */
  font-family: "Font Awesome 5 Free"; /* here is the correct font-family */
  font-style: normal;
  font-weight: 900;
  text-decoration: inherit;
}

.react-datepicker__input-container{
  padding: 3px 5px;
}

.container--color{
  background-color: #F2F6F7;
  padding: 1em;
}

.col--color{
  background-color: white;
 
}

.domain-switch {
  vertical-align: text-top;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-modal-z-index{
  z-index: 1050 !important;
}
.acl--wrapper{
  padding: 1em;
}
.acl--card,
.acl--card__row,
.acl--card__col,
.acl--button__row,
.acl--sub__row,
.acl--sub__checkbox{
  display: flex;
}

.acl--sub__dropdown {
  width: 100%;
  margin-bottom: 5px
}

.acl--card{
  flex-flow: column nowrap;
  min-width: 300px;
  width: 500px;
  max-width: 500px;
  height: auto;
  max-height: 900px;
  margin: 1em;
  padding: 1em;
  align-items: center;
}

.acl--card__row,
.acl--card__col{
  justify-content: center;
}
.acl--card__row{
  flex-flow: row wrap;
    
}
.acl--card__col{
  flex-flow: column wrap;
}

.acl--card__header,
.acl--card__headerOff{
  padding: 0.5em;
  font-weight: 800;
}
.acl--card__header{
  background-color: rgba(123,175,223, 0.5); 
}
.acl--heading__landing{
  text-align: center;
}
.acl--header__title,
.acl--landingCopy,
.acl--card__header,
.acl--card__headerOff{
  text-align: center;
}
.acl--sub__row,
.acl--header__title,
.acl--sub__row,
.acl--card__header,
.acl--card__headerOff{
  width: 100%;
}
.acl--header__title,
.acl--card__headerOff{
  color: whitesmoke;
}
.acl--header__title{
  font-size: 2.345em;
  background-color: rgb(123,175,223);
    
}
.acl--card__headerOff{
  background-color: rgb(190, 5, 5); 
}
.acl--button{
  margin: 0.5em;
}
.acl--sub__row{
  justify-content: space-between;
}
.acl--sub__label{
  align-self: center;
  font-weight: 600;
}
.acl--sub__checkbox{
  align-self: flex-start;
    
}
.acl--selector{
  max-width: 600px;
  margin: 0 auto;
}
.acl--sub__container {
  background-color: white;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  border-radius: 2px;
}

.aclTemplate{
  width: 100%;
  margin-top: 1em;
  height: auto;
  margin: 0 auto;
    

}

.aclTemplate--inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.aclTemplate--mainContainer {
  padding: 10px;
  background-color: #F2F6F7;
  margin-bottom: 1em;
}

.aclTemplate .rt-td{
  display: flex;
  justify-content: center;
  align-items: center;
}

.aclTemplate--inputs{
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.aclTemplate--inputGroup{
  margin: .5em;
}
.aclTemplate--input{
  width: 100%;
}
.aclTemplate--input__title{
  border-bottom: 2px solid black;
}


.warning{
  width: 100%;
  padding: 1em;
  background-color: red;
  color: white;
  text-align: center;
}
.spinner-page {
  width: 100%;
  background-color: black;
  overflow-y: hidden;
  position:absolute;
  top:72px;
  bottom:0;
    
  background: white;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, white, #f7f8fa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}
.tracking-in-expand {
  animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-2-5 11:31:57
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
  
  

.spinner {
  width: 100%;
  height: 100px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: -9999;
}

.loading-text {
  position: relative;
  color: #395D96;
  font-size: 40px;
  font-weight: 600;
}
  
/* .double-bounce1, .double-bounce2 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #1C4587;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  } */
.backend-error-header {
  background-color: darkred !important;
  color: white;
}

.backend-error-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

html,body{
  width: 100vw;
  height: 100vh;
}
.top{
  font-family: 'Raleway', sans-serif;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom{
  font-family: 'Raleway', sans-serif;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.yellow {
  color: yellow;
}

.red {
  color: red;
}

.white {
  color: aliceblue;
}
.session-clock {
  background-color: black;
  padding: 3px;
  text-align: center;
}

.post-alert {
  position: fixed;
  z-index: 1040;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}
  
.Site {
  display: flex;
  background-color: white;
  height: 100%;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
}
.SiteContent {
  flex: 1 0 auto;
  padding: var( — space) var( — space) 0;
  width: 100%;
}
.SiteContent:after {
  content: ‘\00a0’;
  display: block;
  margin-top: var( — space);
  height: 0;
  visibility: hidden;
}

.ReactTags__tagInputField {
  padding:5px; 
  border:2px solid #ccc; 
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
}

.tag-wrapper {
  padding:5px; 
  border:2px solid #ccc; 
  margin-right: 5px;
  position: relative;
  display:inline-block;
  word-wrap:break-word;
  margin-bottom: 5px;

}
.select-margin-override .db-option-text{
  font-size: 14px !important;
}


.modal.show{opacity:1}.modal .modal-content .modal-header{display:flex;align-items:flex-start;justify-content:space-between;border-bottom:1px solid #dee2e6;border-top-left-radius:calc(.3rem - 1px);border-top-right-radius:calc(.3rem - 1px);padding:2rem}.modal .modal-content .modal-footer{padding:2rem}.modal .modal-content .modal-footer button{margin-left:12px}.modal .modal-content .modal-body{padding:1rem 2rem}.modal .modal-content .modal-body h3{font-size:initial}.modal .modal-content .modal-header::after,.modal .modal-content .modal-header::before{display:none}.modal-backdrop.show{opacity:.5}
