.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5, h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

/* .row>* {
    padding: 0;
    width: auto;
} */

.nav-link {
  color: #337ab7;
}

a,
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: #337ab7;
  cursor: pointer;
  text-decoration: none;
}

p {
  margin: 0 0 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.form-label {
  margin-bottom: 5px;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-secondary,
.btn-secondary.disabled, 
.btn-secondary:disabled,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  color: rgb(51, 51, 51);
  background-color: rgb(240, 240, 240);
  border: none;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-default {
  text-shadow: 0 1px 0 #fff;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default.active, .btn-default:active, .btn-default:hover, .open>.dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: all !important;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: .65;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning:hover {
  color: #fff;
  background-color: #eb9316;
  background-position: 0 -15px;
}

.btn-warning.active, .btn-warning:active, .btn-warning:hover, .open>.dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info:hover {
  color: #fff;
  background-color: #2aabd2;
  background-position: 0 -15px;
}

.btn-info.active, .btn-info:active, .btn-info:hover, .open>.dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-success:focus, .btn-success:hover {
  color: #fff;
}

.btn-danger:focus, .btn-danger:hover {
  background-color: #c12e2a;
  background-position: 0 -15px;
}

.btn-danger.active, .btn-danger:active, .btn-danger:hover, .open>.dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger, .btn-default, .btn-info, .btn-primary, .btn-success, .btn-warning {
  text-shadow: 0 -1px 0 rgb(0 0 0 / 20%);
  box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}

.btn-primary.disabled, .btn-primary.disabled.active, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled], .btn-primary[disabled].active, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #265a88;
  background-image: none;
}

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.form-control {
  width: 100%;
  min-height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:not(select) {
  background-image: none;
}

.form-control, output {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-check-input {
  border-color: rgb(51,51,51);
}

.form-check-input:checked {
  background-color: rgb(110,110,110);
  border-color: rgb(51,51,51);
}

.form-check label {
  font-weight: 400;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.btn-close {
  float: right;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

button.btn-close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  border: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.modal-header .btn-close {
  margin-top: -2px;
}

.modal .modal-content .modal-header {
  padding: 1rem !important;
}

.modal .modal-content .modal-footer {
  padding: 1rem !important;
}

.modal-600 {
  min-width: 600px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  transition: width .6s ease;
  overflow: visible;
}

.btn-group-sm>.btn, .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.modal .modal-content .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  padding: 2rem !important;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-default>.panel-heading {
  background-image: linear-gradient(180deg,#f5f5f5 0,#e8e8e8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff5f5f5",endColorstr="#ffe8e8e8",GradientType=0);
  background-repeat: repeat-x;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-default>.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-heading .accordion-button {
  position: default;
  display: default;
  align-items: default;
  padding: 0;
  font-size: auto;
  color: inherit;
  text-align: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
}

.panel-heading .accordion-button:after {
  background: none;
}

.panel-heading .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.panel-heading .accordion-button:focus {
  outline: none;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}