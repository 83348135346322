@import 'https://fonts.googleapis.com/css?family=Maven+Pro&display=swap';

body {
  margin: 0;
  padding: 0;
  font-family: 'Maven Pro', sans-serif;
}

.modal-links-grid {
  width: 800px !important;
}


