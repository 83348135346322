.modal {
  &.show {
    opacity: 1;
  }

  .modal-content {
    .modal-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: calc(.3rem - 1px);
      border-top-right-radius: calc(.3rem - 1px);
      padding: 2rem;
    }

    .modal-footer {
      padding: 2rem;
      button {
        margin-left: 12px;
      }
    }

    .modal-body {
      padding: 1rem 2rem;

      h3 {
        font-size: initial;
      }
    }

    .modal-header::after,
    .modal-header::before {
      display: none;
    }
  }
}

.modal-backdrop {
  &.show {
    opacity: .5;
  }
}