.acl--wrapper{
  padding: 1em;
}
.acl--card,
.acl--card__row,
.acl--card__col,
.acl--button__row,
.acl--sub__row,
.acl--sub__checkbox{
  display: flex;
}

.acl--sub__dropdown {
  width: 100%;
  margin-bottom: 5px
}

.acl--card{
  flex-flow: column nowrap;
  min-width: 300px;
  width: 500px;
  max-width: 500px;
  height: auto;
  max-height: 900px;
  margin: 1em;
  padding: 1em;
  align-items: center;
}

.acl--card__row,
.acl--card__col{
  justify-content: center;
}
.acl--card__row{
  flex-flow: row wrap;
    
}
.acl--card__col{
  flex-flow: column wrap;
}

.acl--card__header,
.acl--card__headerOff{
  padding: 0.5em;
  font-weight: 800;
}
.acl--card__header{
  background-color: rgba(123,175,223, 0.5); 
}
.acl--heading__landing{
  text-align: center;
}
.acl--header__title,
.acl--landingCopy,
.acl--card__header,
.acl--card__headerOff{
  text-align: center;
}
.acl--sub__row,
.acl--header__title,
.acl--sub__row,
.acl--card__header,
.acl--card__headerOff{
  width: 100%;
}
.acl--header__title,
.acl--card__headerOff{
  color: whitesmoke;
}
.acl--header__title{
  font-size: 2.345em;
  background-color: rgb(123,175,223);
    
}
.acl--card__headerOff{
  background-color: rgb(190, 5, 5); 
}
.acl--button{
  margin: 0.5em;
}
.acl--sub__row{
  justify-content: space-between;
}
.acl--sub__label{
  align-self: center;
  font-weight: 600;
}
.acl--sub__checkbox{
  align-self: flex-start;
    
}
.acl--selector{
  max-width: 600px;
  margin: 0 auto;
}
.acl--sub__container {
  background-color: white;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  border-radius: 2px;
}

.aclTemplate{
  width: 100%;
  margin-top: 1em;
  height: auto;
  margin: 0 auto;
    

}

.aclTemplate--inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.aclTemplate--mainContainer {
  padding: 10px;
  background-color: #F2F6F7;
  margin-bottom: 1em;
}

.aclTemplate .rt-td{
  display: flex;
  justify-content: center;
  align-items: center;
}

.aclTemplate--inputs{
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.aclTemplate--inputGroup{
  margin: .5em;
}
.aclTemplate--input{
  width: 100%;
}
.aclTemplate--input__title{
  border-bottom: 2px solid black;
}

