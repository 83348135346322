
@import 'https://fonts.googleapis.com/css?family=Work+Sans';

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body{
  max-width: 100%;
  overflow-x: hidden;
  font-size: 14px;
  font-family:'Work Sans', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(51, 51, 51);
}

.App {
  text-align: center;
}

/* .spinner {
  position: absolute;
  top: 90%;
    left: 50%;
  width: 100%;
  z-index: -1;
  } */

.react-datepicker__header {
  font-size: 10px;

}

.slide-pane__content {
  padding: 0px
}

.react-datepicker__month-container {
  font-size: 11px;
  z-index: 1040;
}

.spinner-local { 
  position: absolute;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  bottom: 50%;
  left: 50%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-wrapper {
  height: 34px;
}

.react-datepicker__input-container {
  border: 1px solid rgb(206, 210, 214);
  border-radius: 0.25rem;
  background-color: #f4f7f9;
  height: 34px;
  padding: 0 !important;
  /* padding: 0.5rem 0.75rem;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5; */
  color: #e4e5e6;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 34px;
  font-size: 17px;
  border: none;
  color: #212529;
  padding: 0 7px;
}

.react-datepicker__input-container input:focus-visible {
  outline: none !important;
}

/* .input-date {
  width: 100%;
  height: 100%;
  border: none;
  color: #212529;
}

.input-date:focus-visible {
  outline: none !important;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.template-small-card {
  height: 180px;
  width: 300px;
  margin: 10px;
  padding: 10px;
  display: inline-block;
  text-align: center;
}

.shadow {
  -webkit-box-shadow: 1px 10px 23px -10px rgba(0,0,0,0.2);
  -moz-box-shadow: 1px 10px 23px -10px rgba(0,0,0,0.2);
  box-shadow: 1px 10px 23px -10px rgba(0,0,0,0.2);
}


@media (min-width: 1200px){
  .container {
    width: 1250px;
  }
}

.fp-prev:before {
  color:#000;
  content: '\f35a'; /* replace / with \ */
  font-family: "Font Awesome 5 Free"; /* here is the correct font-family */
  font-style: normal;
  font-weight: 900;
  text-decoration: inherit;
}

.react-datepicker__input-container{
  padding: 3px 5px;
}

.container--color{
  background-color: #F2F6F7;
  padding: 1em;
}

.col--color{
  background-color: white;
 
}

.domain-switch {
  vertical-align: text-top;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-modal-z-index{
  z-index: 1050 !important;
}