@import 'https://fonts.googleapis.com/css?family=Raleway';

html,body{
  width: 100vw;
  height: 100vh;
}
.top{
  font-family: 'Raleway', sans-serif;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom{
  font-family: 'Raleway', sans-serif;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.yellow {
  color: yellow;
}

.red {
  color: red;
}

.white {
  color: aliceblue;
}
.session-clock {
  background-color: black;
  padding: 3px;
  text-align: center;
}

.post-alert {
  position: fixed;
  z-index: 1040;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}
  
.Site {
  display: flex;
  background-color: white;
  height: 100%;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
}
.SiteContent {
  flex: 1 0 auto;
  padding: var( — space) var( — space) 0;
  width: 100%;
}
.SiteContent:after {
  content: ‘\00a0’;
  display: block;
  margin-top: var( — space);
  height: 0;
  visibility: hidden;
}

.ReactTags__tagInputField {
  padding:5px; 
  border:2px solid #ccc; 
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
}

.tag-wrapper {
  padding:5px; 
  border:2px solid #ccc; 
  margin-right: 5px;
  position: relative;
  display:inline-block;
  word-wrap:break-word;
  margin-bottom: 5px;

}
.select-margin-override .db-option-text{
  font-size: 14px !important;
}

