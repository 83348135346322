.header-container {
  flex: 0 0 auto;
  
  a {
    color: #777777;
    &:visited,
    &:active,
    &:link {
      color: #777777;
    }

    &:active, &:focus {
      text-decoration: none;
    }
  }

  .navbar {
    margin-bottom: 20px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e7e7e7;
    flex-direction: column;
    background-image: linear-gradient(180deg,#fff 0,#f8f8f8);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff",endColorstr="#fff8f8f8",GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 5px rgb(0 0 0 / 10%);
  }

  .nav-item, .dropdown--link {
    &:hover {
      background-color: #71a8dc;
    }
    &.session-clock-nav-item:hover {
      background: none;
    }
  }

  .navbar-brand > img {
    height: 20px;
  }

  .navbar-nav {
    &> .nav-item {
      &> a, &> span {
        position: relative;
        display: block;
        padding: 10px 15px;
        color: #777777;
        cursor: pointer;
      }
    }
  }

  .dropdown--link {
    padding: 10px;
  }

  @media (min-width: 768px) {
    .navbar-nav {
      &> .nav-item {
        float: left;

        &> a, &> span {
          display: block;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
}
