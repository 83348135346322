.backend-error-header {
  background-color: darkred !important;
  color: white;
}

.backend-error-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
